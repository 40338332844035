import {
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  SelectProps,
} from "@mui/material";
import { useFormikContext } from "formik";
import * as colors from "../../util/colors";

export type IStyledSelectProps = SelectProps;

const StyledSelect = <T extends Record<string, any>>(
  props: IStyledSelectProps
) => {
  const formik = useFormikContext<T>();
  const { name = "", label, onChange, ...otherProps } = props;
  const isError =
    formik && formik.touched[name] && Boolean(formik.errors[name]);
  return (
    <FormControl sx={props.fullWidth ? { width: "100%" } : undefined}>
      <FormLabel htmlFor={`${label}-field`}>{label}</FormLabel>
      <Select
        {...otherProps}
        name={name}
        onChange={(e, child) => {
          if (onChange) onChange(e, child);
          if (formik) {
            formik.setTouched({ ...formik.touched, [name]: true }, true);
            formik.handleChange(e);
          }
        }}
        value={otherProps.value || formik?.values[name]}
        onBlur={formik?.handleBlur}
        error={isError}
        id={`${label}-field`}
        sx={{
          ...otherProps.sx,
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderWidth: 2,
            },
          },
          "& .MuiInputBase-root": {
            backgroundColor: "white",
          },
        }}
        MenuProps={{
          ...otherProps.MenuProps,
          autoFocus: false,
          PaperProps: {
            sx: {
              maxHeight: "calc(50vh - 200px)",
              minHeight: "192px",
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: colors.Violet[500],
                color: colors.WHITE,
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: colors.Violet[100],
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: colors.Violet[600],
              },
            },
          },
        }}
      />
      {formik && formik.touched[name] ? (
        <FormHelperText error={isError}>
          {formik.errors[name]?.toLocaleString()}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default StyledSelect;
