import { createAsyncThunk } from "@reduxjs/toolkit";
import * as authApi from "../../api/auth.api";
import {
  setAccessToken,
  setIsAuthenticated,
  setRefreshToken,
  setUserJobs,
  setUserMe,
} from "../slices/auth.slice";
import { openSnackbar } from "../slices/snackbar.slice";
import { AxiosError } from "axios";
import { parseErrorResponse } from "../../util/util";

export const login = createAsyncThunk(
  "auth/login",
  async (payload: { email: string; password: string }, { dispatch }) => {
    try {
      const data = await authApi.login(payload);
      dispatch(setRefreshToken(data.refresh));
      dispatch(setAccessToken(data.access));
      dispatch(setIsAuthenticated(true));
      return true;
    } catch (e) {
      return false;
    }
  },
);

export const refreshLogin = createAsyncThunk(
  "auth/refreshLogin",
  async (refreshToken: string, { dispatch }) => {
    const data = await authApi.refreshLogin(refreshToken);
    dispatch(setAccessToken(data.access));
    dispatch(setRefreshToken(refreshToken));
    dispatch(setIsAuthenticated(true));
    return data;
  },
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (
    userData: {
      email: string;
      password: string;
      confirm_password: string;
      first_name: string;
      last_name: string;
      profile: {
        job_category: string;
        job_name: string;
        job_seniority: string;
      };
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await authApi.signUp(userData);
      return true;
    } catch (e) {
      if (e instanceof AxiosError) {
        dispatch(
          openSnackbar({
            message: e.response?.data
              ? parseErrorResponse(e)
              : "There was an issue with this request",
            severity: "error",
          }),
        );
        throw rejectWithValue(e.response?.data);
      }
    }
  },
);

export const activate = createAsyncThunk(
  "auth/activate",
  async (token: string, { dispatch, rejectWithValue }) => {
    try {
      await authApi.activate(token);
      return true;
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data["user"]) {
          dispatch(
            openSnackbar({
              message: e.response?.data
                ? parseErrorResponse(e)
                : "There was an issue with this request",
              severity: "error",
            }),
          );
          throw rejectWithValue(e.response?.data);
        }
      }
    }
  },
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (email: string) => {
    return await authApi.resetPassword(email);
  },
);

export const resetPasswordConfirm = createAsyncThunk(
  "auth/resetPasswordConfirm",
  async (
    body: { password: string; signature: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await authApi.resetPasswordConfirm(body.password, body.signature);
      return true;
    } catch (e) {
      if (e instanceof AxiosError) {
        dispatch(
          openSnackbar({
            message: e.response?.data
              ? parseErrorResponse(e)
              : "There was an issue with this request",
            severity: "error",
          }),
        );
        if (e.response?.data["signature"]) {
          throw rejectWithValue(e.response?.data);
        }
      }
    }
  },
);

export const fetchUserInfo = createAsyncThunk(
  "auth/fetchUserInfo",
  async (_, { dispatch }) => {
    const data = await authApi.fetchUserInfo();
    dispatch(setUserMe(data));
  },
);

export const fetchUserJobs = createAsyncThunk(
  "auth/fetchUserJobs",
  async (_, { dispatch }) => {
    const data = await authApi.fetchUserJobs();
    dispatch(setUserJobs(data));
  },
);
