import { Grid, useTheme, MenuItem, ListSubheader } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { routes } from "../../util/routes";
import StyledButton from "../UI/StyledButton";
import StyledTextfield from "../UI/StyledTextfield";
import { fetchUserJobs, signUp } from "../../redux/thunks/auth.thunk";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  UserJob,
  getUserJobs,
  setUserInfo,
} from "../../redux/slices/auth.slice";
import { useAsyncEffect, useMobile } from "../../util/util";
import React, { useEffect, useMemo, useState } from "react";
import StyledSelect from "../UI/StyledSelect";
import * as colors from "../../util/colors";

const stageOne = {
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
};

export const passwordSchema = {
  password: yup
    .string()
    .min(9)
    .required()
    .label("Password")
    .matches(/\d/, "You must have at least one number")
    .matches(/[A-Za-z]/, "You must have at least one letter"),
  confirmPassword: yup
    .string()
    .required()
    .label("Confirm Password")
    .oneOf([yup.ref("password")], "Passwords do not match"),
};

const stageTwo = {
  username: yup.string().required().label("Email Address"),
  ...passwordSchema,
};

const stageThree = {
  jobFunction: yup.string().required().label("Job Function"),
  seniority: yup.string().required().label("Seniority"),
};

const otherSchema = yup
  .object()
  .shape({ ...stageThree, jobName: yup.string().required().label("Job") });

const stageOneSchema = yup.object().shape(stageOne);
const stageTwoSchema = yup.object().shape(stageTwo);
const stageThreeSchema = yup.object().shape(stageThree);

const DetectInternal = ({
  setInternal,
}: {
  setInternal: (x: boolean) => void;
}) => {
  const { values } = useFormikContext<Record<string, any>>();
  useEffect(() => {
    if (values.username.includes("gatik.ai")) {
      setInternal(true);
    } else {
      setInternal(false);
    }
  }, [setInternal, values.username]);
  return null;
};

const DetectOther = ({ setOther }: { setOther: (x: boolean) => void }) => {
  const { values, setFieldValue } = useFormikContext<Record<string, any>>();
  const userJobs = useAppSelector(getUserJobs);
  useEffect(() => {
    if (
      userJobs.user_jobs
        .find((job) => job.id === values.jobFunction)
        ?.name.toLowerCase()
        .includes("other")
    ) {
      setOther(true);
    } else {
      setFieldValue("jobName", null);
      setOther(false);
    }
  }, [setFieldValue, setOther, userJobs.user_jobs, values.jobFunction]);
  return null;
};

export interface ISignUpFormProps {}

export default function SignUpForm(props: ISignUpFormProps) {
  const theme = useTheme();
  const { page } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userJobs = useAppSelector(getUserJobs);
  const [isInternal, setIsInternal] = useState(false);
  const [other, setOther] = useState(false);
  const isMobile = useMobile();

  useAsyncEffect(async () => {
    dispatch(fetchUserJobs());
  }, []);

  const { categoriesMap, categories, seniorities } = useMemo(() => {
    const seniorities: Array<{ name: string; order: number; id: string }> =
      Array.from(userJobs.user_job_seniorities);
    const categories = Array.from(userJobs.user_job_categories).filter(
      (category) =>
        isInternal
          ? category.type === "internal"
          : category.type !== "internal",
    );

    let categoriesMap = new Map<string, UserJob[]>();

    categories.forEach((category) =>
      categoriesMap.set(
        category.id,
        userJobs.user_jobs
          .filter((job) => job.category === category.id)
          .sort((a, b) => (a.order > b.order ? 1 : -1)),
      ),
    );

    categories.sort((a, b) => (a.order > b.order ? 1 : -1));
    seniorities.sort((a, b) => (a.order > b.order ? 1 : -1));
    return { categories, seniorities, categoriesMap };
  }, [
    isInternal,
    userJobs.user_job_categories,
    userJobs.user_job_seniorities,
    userJobs.user_jobs,
  ]);

  if (!page) {
    navigate(routes.loginRoutes.root);
    return null;
  }

  const numericPage = parseInt(page);
  let mainContent = <></>;
  let schema = undefined;
  switch (numericPage) {
    case 1:
      schema = stageOneSchema;
      mainContent = (
        <>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="firstName"
              name="firstName"
              fullWidth
              color="primary"
              label="First Name"
            />
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="lastName"
              name="lastName"
              fullWidth
              color="primary"
              label="Last Name"
            />
          </Grid>
        </>
      );
      break;
    case 2:
      schema = stageTwoSchema;
      mainContent = (
        <>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="username"
              name="username"
              fullWidth
              color="primary"
              label="Email Address"
            />
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="password"
              name="password"
              fullWidth
              color="primary"
              label="Password"
              type="password"
            />
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              key="confirmPassword"
              name="confirmPassword"
              fullWidth
              color="primary"
              label="Re-enter Password"
              type="password"
            />
          </Grid>
        </>
      );
      break;
    case 3:
      schema = other ? otherSchema : stageThreeSchema;
      const selectOptions: React.ReactElement[] = [];
      categories.forEach((category) => {
        selectOptions.push(
          <ListSubheader>
            <b
              style={{
                fontSize: "16px",
                color: colors.BlueGray[800],
              }}
            >
              {category.name}
            </b>
          </ListSubheader>,
        );
        categoriesMap.get(category.id)?.forEach((job) =>
          selectOptions.push(
            <MenuItem value={job.id} sx={isMobile ? { height: "45px" } : {}}>
              {job.name}
            </MenuItem>,
          ),
        );
      });
      mainContent = (
        <>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledSelect
              name="jobFunction"
              fullWidth
              label="What is your job function?"
            >
              {selectOptions}
            </StyledSelect>
          </Grid>
          {other && (
            <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
              <StyledTextfield
                label="How would you describe your job?"
                name="jobName"
                fullWidth
              />
            </Grid>
          )}
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledSelect
              name="seniority"
              fullWidth
              label="What is your level of seniority?"
            >
              {seniorities.map((seniority) => (
                <MenuItem value={seniority.id}>{seniority.name}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
        </>
      );
      break;
    default:
      break;
  }
  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        jobFunction: "",
        seniority: "",
        jobName: null,
      }}
      onSubmit={async (values) => {
        if (numericPage !== 3) {
          navigate(routes.loginRoutes.signUp(numericPage + 1));
          return;
        }
        dispatch(
          setUserInfo({
            email: values.username,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            profile: {
              job_category:
                userJobs.user_jobs.find((job) => job.id === values.jobFunction)
                  ?.category || "",
              job_name:
                values.jobName ||
                userJobs.user_jobs.find((job) => job.id === values.jobFunction)
                  ?.name ||
                "",
              job_seniority: values.seniority,
            },
          }),
        );
        const action = await dispatch(
          signUp({
            email: values.username,
            password: values.password,
            confirm_password: values.confirmPassword,
            first_name: values.firstName,
            last_name: values.lastName,
            profile: {
              job_category:
                userJobs.user_jobs.find((job) => job.id === values.jobFunction)
                  ?.category || "",
              job_name:
                values.jobName ||
                userJobs.user_jobs.find((job) => job.id === values.jobFunction)
                  ?.name ||
                "",
              job_seniority: values.seniority,
            },
          }),
        );
        if (action.type === "auth/signUp/rejected") {
          if (action.payload && Object.keys(action.payload).includes("user")) {
            navigate(routes.loginRoutes.resetPassword);
          } else {
            navigate(-1);
          }
        } else if (action.payload) {
          navigate({
            pathname: routes.loginRoutes.inbox,
            search: "?action=CREATE",
          });
        }
      }}
      validationSchema={schema}
      validateOnChange={true}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          flexGrow: 1,
        }}
      >
        {mainContent}
        <DetectInternal setInternal={setIsInternal} />
        <DetectOther setOther={setOther} />
        <Grid item sx={{ marginTop: theme.spacing(4), width: "100%" }}>
          <StyledButton
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            fullWidth
            disabled={false}
          >
            {numericPage === 3 ? "Create my account" : "Continue"}
          </StyledButton>
        </Grid>
      </Grid>
    </Formik>
  );
}
