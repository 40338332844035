import { Grid } from "@mui/material";
import * as typeset from "../../util/typeset";
import StyledDialog from "../../components/UI/StyledDialog";
import { ReactComponent as BackArrow } from "../../assets/back_arrow.svg";
import StyledTypography from "../../components/UI/StyledTypography";
import { Formik } from "formik";
import { useRef } from "react";
import { Customer } from "../../types/customer.type";

export interface TrackingAdminToolsDialogProps {
  customer?: Customer;
  setTo: "on" | "off";
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const TrackingAdminToolsDialog = (props: TrackingAdminToolsDialogProps) => {
  const { customer, setTo, onSubmit, onCancel, isOpen } = props;
  const formRef = useRef<any>(null);

  const submitChange = () => {
    formRef.current.handleSubmit();
  };

  const reviewScreen = () => {
    return (
      <>
        <Grid item sx={{ marginTop: 0 }}>
          <StyledTypography sx={typeset.body} style={{ lineHeight: 1.75 }}>
            Are you sure you want to apply these edits to{" "}
            <b>{customer?.name}</b>?
          </StyledTypography>
          <StyledTypography sx={typeset.body}>
            Note that turning Manual Mode on will display a red banner alert to
            all {customer?.name} users on the Omni app to rely on manual backup
            procedures for communicating ETAs and arrival notifications.
          </StyledTypography>
        </Grid>
        <Grid item sx={{ marginTop: 5 }}>
          <StyledTypography sx={typeset.body}>
            Manual tracking mode changed:
          </StyledTypography>
        </Grid>
        <Grid item sx={{ marginTop: 1 }}>
          <StyledTypography sx={typeset.body}>
            <b>
              {setTo === "on" ? "Off" : "On"}
              <BackArrow
                style={{
                  transform: "rotate(180deg)",
                  verticalAlign: "middle",
                  margin: "0 5px 3px",
                }}
              />
              {setTo === "on" ? "On" : "Off"}
            </b>
          </StyledTypography>
        </Grid>
      </>
    );
  };
  const modalBody = () => {
    return (
      <Grid
        container
        direction="column"
        sx={{
          // padding + margin of default modal styles = 128px
          width: "calc(100vw - 128px)",
          maxWidth: "592px",
          padding: "20px",
          paddingTop: 4,
        }}
      >
        {reviewScreen()}
      </Grid>
    );
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ customer: customer, setTo: setTo }}
      enableReinitialize={true}
      onSubmit={async () => {
        onSubmit();
      }}
    >
      {({ isValid, dirty }) => (
        <StyledDialog
          onClose={onCancel}
          onPrimaryClick={() => submitChange()}
          onClickClose={onCancel}
          primaryButtonText={"Confirm"}
          onSecondaryClick={onCancel}
          headerText={"Review changes"}
          open={isOpen}
          maxWidth={false}
        >
          {modalBody()}
        </StyledDialog>
      )}
    </Formik>
  );
};

export default TrackingAdminToolsDialog;
